import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';
import '../fonts/fonts.css';

import { GlobalStyle } from './GlobalStyle';

const Layout = ({ children, transitionStatus, page }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={description} />

        <link rel='icon' type='image/png' href={`${withPrefix('/')}img/32px-32px.png`} sizes='32x32' />
        <link rel='icon' type='image/png' href={`${withPrefix('/')}img/16px-16px.png`} sizes='16x16' />

        <meta name='theme-color' content='#fff' />

        <meta property='og:type' content='business.business' />
        <meta property='og:title' content={title} />
        <meta property='og:url' content='/' />
        <meta property='og:image' content={`${withPrefix('/')}img/og-8080-studio.jpg`} />

        <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>

      </Helmet>

      <div>
        {children}
      </div>
    </div>
  );
};

export default Layout;
